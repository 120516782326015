/* Styles specific to layout2 */
.layout2.text-container {
  align-items: center;
  height: auto;
  padding: 0 65px;
}

.layout2.img-container {
  align-items: flex-end;  
  height: 335px;
}

.layout2 .about-img {
  height: 300px;
  width: 100%;
  object-fit: cover;
}

.layout2 .title {
  text-align: end; 
}

@media (max-width: 768px) {
  .layout2 .text-container {
    padding: 4% 30px;
  }

  .layout2 .title {
    font-size: 28px;
  }

  .layout2 .paragraph {
    font-size: 14px;
  }

  .layout2 .about-img {
    height: 150px;
  }
}

