/* Enter from the right */
.slide-enter, .slide-from-left-enter {
  transform: translateX(100%);
  opacity: 0;
}
.slide-enter-active, .slide-from-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: opacity 300ms, transform 300ms;
}

/* Enter from the left */
.slide-from-left-enter {
  transform: translateX(-100%);
}
.slide-from-left-enter-active {
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}


.slide-exit, .slide-from-left-exit {
  transform: translateX(0);
  opacity: 1;
}
.slide-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: opacity 200ms, transform 200ms;
}

.slide-from-left-exit {
  transform: translateX(0);
  opacity: 1;
}
.slide-from-left-exit-active {
  transform: translateX(100%);
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}


.fade-in.title {
  opacity: 0;
  transition: opacity 600ms ease-in-out 200ms; 
}

.fade-in.paragraph {
  opacity: 0;
  transition: opacity 500ms ease-in-out 700ms;
}

.slide-enter-done .fade-in.title,
.slide-enter-done .fade-in.paragraph {
  opacity: 1;
}

.slide-from-left-enter-done .fade-in.title,
.slide-from-left-enter-done .fade-in.paragraph {
  opacity: 1;
}