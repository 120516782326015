.about-section section {
  position: relative;
  background-color: #ecebe8;
  font-family: 'Martel Sans', sans-serif;
  height: 520px;
  display: flex;
  align-items: center; 
  justify-content: center; 
}

.about-title {
  color: var(--black);
  font-size: 20px;
}

.page-title {
  color: rgba(25, 25, 26, 0.4);
  font-size: 75px;
}

.page-paragraph {
  color: var(--black);
  font-size: 18px;
}

@media (max-width: 768px) {
  .about-title {
    font-size: 12px;
  }

  .page-title {
    font-size: 22px;
  }

  .page-paragraph {
    font-size: 12px;
  }
}
