.follow-background {
  position: relative;
  overflow: hidden;
}

.background-image {
  background-image: url('./background.png');
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: .3;
}
