.page-container {
  background-color: #ecebe8;
  color: #19191a;
  font-family: 'Martel Sans', sans-serif;
}

.text-container {
  align-items: flex-start;
  justify-content: center;
  padding: 65px;
}

.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-img {
  width: 100%;
  height: 520px;
  object-fit: cover;
}

.title {
  color: rgba(25, 25, 26, 0.4);
  font-size: 50px;
  text-align: left;
  padding-left: 15%;
  padding-bottom: 20px;
}

.paragraph {
  font-size: 18px;
  text-align: left;
  color: rgba(25, 25, 26, 0.7);
  padding-left: 15%;
}

@media (max-width: 768px) {
  body .about-img {
    height: 100%;
    max-width: 100%;
  }

  body .title {
    font-size: 28px;
    padding-left: 18%;
  }

  body .paragraph {
    font-size: 14px;
    padding-left: 18%;

  }


}
