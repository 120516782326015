.hero {
  position: sticky;
  top: 0;
  z-index: -1; 
  width: 100%;
  opacity: .8;
}

.hero img {
  width: 100%;
  height: 600px;
  object-fit: cover;
}

/* Media query for mobile screens */
@media (max-width: 768px) {
  .hero img {
    height: 200px;
  }
}
