.app-bar.top {
  transition: background-color 0.5s ease, color 0.5s ease;
  background-color: var(--white) !important;
  color: var(--blue) !important;
}

.app-bar.scrolled {
  transition: background-color 0.5s ease, color 0.5s ease;
  background-color: var(--headerBlue) !important;
  color: var(--white) !important;
}
.app-bar.top .myButton:hover::after {
  background-color: var(--blue);
}

.toolbar {
  display: flex;
  justify-content: space-between;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-container:hover {
  opacity: 0.7;
}


.logo {
  height: 100px;
  width: auto;
}

.scrolled {
  /* add styles for scrolled state if needed */
}

.myButton {
  position: relative;
}

.myButton:hover {
  background-color: transparent;
  box-shadow: none;
}

.myButton:focus-visible {
  outline: none;
  box-shadow: none;
}


.myButton:hover::after {
  width: 100%;
}

.myButton::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;  
  background-color: var(--white); 
  transition: width 0.3s ease-in-out;
}


.myButton:active {
  color: #0e4c95; 
}

