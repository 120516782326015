@import url('https://fonts.googleapis.com/css2?family=Martel+Sans:wght@200&display=swap');

:root {
  --blue: #0e4c95;
  --headerBlue: rgba(14, 76, 149, 0.9);
  --black: #1d1d1f;
  --white: #f3f2f2;
  --grey: #a1a1a1;
}
html {
  scroll-behavior: smooth;
}

html,
body {
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
}
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ecebe8;
  color: var(--black);
  font-family: 'Martel Sans', sans-serif;
}
